exports = module.exports = require("../../../../node_modules/.pnpm/css-loader@1.0.1_webpack@4.47.0/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".corePlatformUI--core-utils-sessionTimeout-modelalert_less--icon_st {\n  padding-top: 4px;\n  padding-bottom: 12px;\n  text-align: center;\n}\n.corePlatformUI--core-utils-sessionTimeout-modelalert_less--icon_text_st {\n  font-size: 24px;\n  font-weight: 300;\n  line-height: 30px;\n  letter-spacing: 0.5px;\n  color: var(--hbr-color-neutral-text);\n  text-align: center;\n  padding-top: 0px;\n  padding-bottom: 12px;\n}\n.corePlatformUI--core-utils-sessionTimeout-modelalert_less--content_st {\n  font-size: 14px;\n  color: var(--hbr-color-neutral-border-weak);\n  font-weight: 300;\n  letter-spacing: 0.5px;\n  line-height: 20px;\n  text-align: center;\n  padding-top: 0px;\n  padding-bottom: 24px;\n}\n", ""]);

// exports
exports.locals = {
	"icon_st": "corePlatformUI--core-utils-sessionTimeout-modelalert_less--icon_st",
	"iconSt": "corePlatformUI--core-utils-sessionTimeout-modelalert_less--icon_st",
	"icon_text_st": "corePlatformUI--core-utils-sessionTimeout-modelalert_less--icon_text_st",
	"iconTextSt": "corePlatformUI--core-utils-sessionTimeout-modelalert_less--icon_text_st",
	"content_st": "corePlatformUI--core-utils-sessionTimeout-modelalert_less--content_st",
	"contentSt": "corePlatformUI--core-utils-sessionTimeout-modelalert_less--content_st"
};