const axios = require("axios-0.x");

const isPollerRequest = (config = {}) => {
  return !("poller" in config) ? false : config.poller;
};

axios.interceptors.request.use(config => {
  config.headers["X-Requested-With"] = "XMLHttpRequest";
  config.headers["X-ORIGIN"] = "PUI";
  config.headers["X-CSRF-TOKEN"] = localStorage.getItem("csrf");
  if (isPollerRequest(config)) {
    config.headers["X-REFRESH-TOKEN"] = false;
  }
  if (config.preventCache) {
    config.params = Object.assign({}, config.params, {
      __preventCache: new Date().getTime()
    });
  }
  return config;
});

axios.interceptors.response.use(res => {
  if (res && res.headers && res.headers["x-csrf-token"]) {
    localStorage.setItem("csrf", res.headers["x-csrf-token"]);
  }
  return Promise.resolve(res);
}, err => {
  if (err && err.response && err.response.status === 401) {
    // TODO: uncomment the line below, once maglev API return custom header for RBAC Identification.
    // auth.signout();
  }
  if (err && err.response && err.response.status === 504) {
    // if (!requestPaths504[err.response.config.url]) {
    //   // Store the the request path
    //   requestPaths504[err.response.config.url] = true;
    //   // Show Toast
    //   const dnxToast = document.createElement("dnx-toast");
    //   document.body.appendChild(dnxToast);
    //   dnxToast.toast({
    //     label: "Service Error",
    //     message:
    //       err.response.data.message +
    //       "<br /><a href='/dna/systemSettings/system360?sidebar=services'>System 360</a>",
    //     flavor: "error",
    //     options: {
    //       isHtml: true
    //     }
    //   });
    // }
  }
  return Promise.reject(err);
});

module.exports = axios;
