const $ = require("jquery");
const axios = require("axios").default;

module.exports = {
  authorize: (user, pass) => {
    const dfd = new $.Deferred();
    const params = new URLSearchParams();
    params.append("username", user);
    params.append("passphrase", pass);
    const headers = { "X-WEB-UI": true };
    axios.post("/api/system/v1/auth/login", params, { headers }).then(
      (response) => {
        const csrfToken = response.headers["x-csrf-token"];
        if (csrfToken) {
          localStorage.setItem("csrf", csrfToken);
          window.CSRF = csrfToken;
          if (process.env.NODE_ENV === "development") {
            document.cookie = `CSRF=${window.CSRF};path=/`;
          }
        }
        localStorage.setItem("x-password-expiry-day", response.headers["x-password-expiry-days"]);
        dfd.resolve(response.data);
      })
      .catch(error => {
        dfd.reject(error.response);
      });
    return dfd.promise();
  },

  signout: () => axios.get("/api/system/v1/identitymgmt/logoff")
};
