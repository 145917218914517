import React from "react";
import serviceRegistry from "core/utils/serviceRegistry";
import errorImg from "./error.svg";
import { DnxBanner, DnxButton } from "../../utils/dnxCustomElementsReactLoader";

import getRandomBackground from "./getRandomBackground";

const useProductName = () => {
  const [name, setName] = React.useState("");
  React.useEffect(() => {
    serviceRegistry
      .getService("cisco.dna.core.product", "branding")
      .then((b) => setName(b.productName));
  }, []);
  return name;
};

const useFailureReason = () => {
  const params = new URLSearchParams(window.location.search);
  const code = params.get("errorCode") || "";
  const productName = useProductName();

  switch (code) {
  //"Error: Invalid scope."
  case "IDMSSO101":
    return "IDMSSO101: Please contact your administrator to fix this issue.";

    //"Error: Missing email id.",
  case "IDMSSO102":
    return "IDMSSO102: Please contact your administrator to fix this issue.";

  case "IDMSSO103":
    return "Email format is invalid.";

  case "IDMSSO104":
    return "Email domain is invalid.";

  case "IDMSSO105":
    return "This tenant's Single Sign On configuration may be invalid.";

  case "IDMSSO106":
    return "Your account is missing information required by the tenant's Single Sign On configuration.";

  case "IDMSSO107":
    return "Your account does not match the tenant's Single Sign On configuration.";

    //"Error: Email Id missing.",
  case "IDMSSO108":
    return "IDMSSO108: Please contact your administrator to fix this issue.";

    //"Error: Roles do not match.",
  case "IDMSSO109":
    return "IDMSSO109: Please contact your administrator to fix this issue.";

    // Cross launched from a dashboard, but user isn't here
  case "IDMSSO110":
    return `Unknown user on ${productName}. Please contact admin for access`;

  case "PUI1":
    return "You are not associated with any tenants.";

  case "PUI2": {
    const { region, offerId } = window.platformUI;
    return `You do not have access to either this region (${region}) or offer (${offerId}) or both.`;
  }

  // Failed login invite
  case "PUI3":
    return "This invitation is no longer valid.";

  case "PUI4":
    return "An unexpected error occured, please try to login again.";

  default:
    return "You do not have permission to access this website.";
  }
};

export function FailedLogin() {
  const productName = useProductName();
  const reason = useFailureReason();

  React.useEffect(() => {
    const img = getRandomBackground();
    document.body.style.background = `url('${img}')`;
  }, []);

  return (
    <div className="login-box-container">
      <div className="login-box" style={{ maxWidth: 500 }}>
        <span className="icon-cisco" aria-hidden="true"></span>

        <div className="brand-title" role="heading" aria-level={1}>
          <div className="branding-strong">Cisco</div>
          <div className="branding-sub">{productName}</div>
        </div>

        <div className="login-box-failure">
          <DnxBanner
            hideRemoveGutton
            config={{ type: "error", message: reason }}
            data-testid="login_banner"
          />

          <img src={errorImg} width="250" height="175" alt=""/>

          <DnxButton
            data-testid="failedLogin__toCisco"
            label="Back to Login"
            click={() => window.location.assign("/")}
          />
        </div>
      </div>
    </div>
  );
}
