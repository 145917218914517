define({
  "maintenance_in_progress": "Maintenance in progress",
  "restoring_backup": "Restoring backup",
  "system_scale_in_progress": "System scale in progress",
  "system_update_in_progress": "System update in progress",
  "checking_maintenance_mode": "Checking maintenance mode status...",

  seconds: "Second(s)",
  minutes: "Minute(s)",
  took: "Took",
  complete: "complete",
  of: "of",
  completed: "tasks completed",
  hide: "Hide details",
  show: "Show details",
  phase: "Phase",
  please: "When the upgrade is complete, you will be redirected to Cisco Catalyst Center."
});

