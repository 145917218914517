import React from "react";

import "./less/login.less";

import axios from "axios";
import nls from "i18n!./nls/i18n_login";
import restRelease from "rest/release";

import auth from "core/utils/auth";
import serviceRegistry from "core/utils/serviceRegistry";
import cookie from "core/apicem/utils/cookie";
import getRandomBackground from "./getRandomBackground";
import rbac from "core/utils/rbac";

import {
  DnxTextfield,
  DnxButton
} from "../../utils/dnxCustomElementsReactLoader";

import {
  initialState,
  AlertState,
  reducer,
  input,
  brandingReady,
  hasLoginMessage,
  requestStarted,
  loginSucceeded,
  loginFailed,
  forceLoggedOut,
  idleLogOut
} from "./store";

class Login extends React.Component {
  // Set initial state
  state = { ...initialState };

  /** We'll use this to focus on component mount */
  usernameInput = null;

  componentDidMount() {
    this.randomizeBackground();
    this.fetchLoginMessage();

    // Fetch branding
    serviceRegistry
      .getService("cisco.dna.core.product", "branding")
      .then(this.onLoadBranding);

    // todo: update comment explanation - copied from old login page
    cookie.removeItem("idleTimeoutExpiresAt");

    // If recentled auto thrown out then display Token no longer valid
    const ticketLogout = cookie.getItem("invalidServiceTicketLogout");
    if (ticketLogout) {
      this.dispatch(forceLoggedOut(ticketLogout));
    }

    if (window.sessionStorage.getItem("idleTimerLoggedOut")) {
      this.dispatch(idleLogOut());
    }

    if (this.usernameInput) {
      // So there's this issue where <dnx-textfield> doesn't fully render it's internals yet when react has mounted...
      // So what we're doing here is gonna "skip" a render frame so we can query the input and focus on it on page load
      setTimeout(() => {
        const input = this.usernameInput.querySelector ("input");
        input && input.focus();
      }, 1);
    }
  }

  onLoadBranding = branding => {
    this.dispatch(brandingReady(branding));

    // The [prem] login page doesn't run from within shell,
    // so the title needs to be updated manually
    document.title = `Login - ${branding.qualifiedName}`;
  };

  /** Fetches a random image from a list and sets it to the background */
  randomizeBackground = () => {
    const img = getRandomBackground();
    document.body.style.background = `url('${img}')`;
  };

  /** User defined message to display under login box */
  fetchLoginMessage = () => axios
    .get("/loginMessage")
    .then(r => r.data)
    .then(data => {
      if (data.message) {
        this.dispatch(hasLoginMessage(data.message));
      }
    });

  /** Update state through the reducer */
  dispatch = action => {
    this.setState(state => reducer(state, action));
  };

  /** When a user types into one of the input boxes */
  handleInput = key => e => this.dispatch(input(key, e.target.value));

  /** @type {React.FormEventHandler<HTMLFormElement>} */
  handleLogin = (e) => {
    e.preventDefault();
    this.dispatch(requestStarted());

    // Submit request
    auth.authorize(this.state.username, this.state.password)
      .then(this.onLoginSuccess)
      .catch(this.onLoginError);
  };

  onLoginSuccess = () => {
    window.sessionStorage.removeItem("idleTimerLoggedOut");
    rbac.clearCached();
    this.dispatch(loginSucceeded());

    const updateRelease = restRelease
      .getCurrentRelease()
      .then(version => {
        localStorage.setItem("releaseVersion", version);
      })
      .catch(() => {});

    updateRelease.then(() => window.location.assign(window.location.href));
  };

  onLoginError = (error) => {
    this.dispatch(loginFailed(error.status, error.data));
  };

  render() {
    const { state } = this;
    const isLoading = state.loading;

    // Lets also disable submit when the form is not completed
    const bothFieldsHaveInput = !!state.username && !!state.password;
    const disableSubmit = isLoading || !bothFieldsHaveInput;

    const showAlert = state.alertState !== AlertState.None;

    return (
      <div className="login-box-container">
        <div className="login-box">
          <span className="icon-cisco" aria-hidden="true"></span>

          <div className="brand-title" role="heading" aria-level={1}>
            <div className="branding-strong">Cisco</div>
            <div className="branding-sub">{state.productName}</div>
          </div>

          <div className="branding-description">
            {state.productDescription}
          </div>

          {state.browserWarning && (
            <div className="text-danger browser-warning">
              <small className="supported-browser-warning">{state.browserWarning}</small>
            </div>
          )}

          <form className="fields" onSubmit={this.handleLogin}>
            {showAlert && <Alert text={state.alertText} state={state.alertState}/>}

            <DnxTextfield
              data-testid="login-username"
              label={nls.label_username}
              value={state.username}
              autocomplete="username"
              disabled={isLoading}
              ref={el => (this.usernameInput = el)}
              deleteicon={false}
              input={this.handleInput("username")}/>

            <DnxTextfield
              data-testid="login-password"
              password={true}
              label={nls.label_password}
              value={state.password}
              autocomplete="current-password"
              disabled={isLoading}
              input={this.handleInput("password")}/>

            <DnxButton
              data-testid="login-submit"
              type="submit"
              label={nls.label_login}
              disabled={disableSubmit}/>
          </form>
        </div>

        {state.loginMessage && <LoginMessage message={state.loginMessage}/>}
      </div>

    );
  }
}

/** The alert that shows up above the login */
const Alert = ({ text, state }) => {
  const alertClass = {
    [AlertState.Error]: "alert-danger",
    [AlertState.Warning]: "alert-warning",
    [AlertState.Success]: "alert-success"
  }[state];

  const color = {
    [AlertState.Error]: "var(--error-color, #00AB50)",
    [AlertState.Warning]: "var(--warning-color, #D08102)",
    [AlertState.Success]: "var(--success-color, #D4371C)"
  }[state];

  const classes = `alert ${alertClass}`;

  return (
    <div className={classes} style={{ backgroundColor: color, border: color }} role="alert">{text}</div>
  );
};

const LoginMessage = ({ message }) => (
  <div className="login-message-container">
    <div className="login-message">
      {message}
    </div>
  </div>
);

export default Login;
