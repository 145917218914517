const axios = require("axios");
const serviceRegistry = require("core/utils/serviceRegistry");


const getEventValue = data => ({
  title: document.title,
  platform: (window.navigator || {}).platform,
  userAgent: (window.navigator || {}).userAgent,
  packageVersion: window.RELEASE_VERSION,
  data
});

/**
   * Generate random GUID
   * @return {String}   Random GUID
   */
const guid = (function() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return function() {
    return (
      `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
    );
  };
})();

module.exports = Object.freeze({
  postEvent: function(data) {
    return serviceRegistry.getService("cisco.dna.core.product", "branding").then(branding => {
      const postData = Object.assign({},
        data,
        { eventURL: window.location.pathname + window.location.search },
        { eventValue: JSON.stringify(getEventValue({
          ...data.eventValue,
          ...{
            app: branding.headerSub,
            version: branding.version
          }
        }))
        }
      );

      return new Promise((resolve, reject) =>
        axios.post(`${window.API_URL_PREFIX}/telemetry/telemetryevent`, postData)
          .then(resolve)
          .catch((...stuff) => {
            return reject(...stuff);
          })
      );
    });
  },
  postStats: function(data) {
    const postData = Object.assign({}, data, { id: guid() });
    return new Promise((resolve, reject) =>
      axios.post(`${window.API_URL_PREFIX}/telemetry/telemetryapistats`, postData)
        .then(resolve)
        .catch((...stuff) => {
          return reject(...stuff);
        })
    );
  }
});
