import React from "react";
import ReactDOM from "react-dom";

import Login from "./login";
import { FailedLogin } from "./FailedLogin";

export function init(applyToDom) {
  const container = document.querySelector(applyToDom);
  const { pathname } = window.location;
  if (pathname === "/auth/failedLogin") {
    ReactDOM.render(<FailedLogin/>, container);
  } else {
    ReactDOM.render(<Login/>, container);
  }
}
