define([
  "jquery",
  "core/utils/auth",
  "core/utils/pluginNavigation",
  "hbs!core/widgets/header/userMenu",
  "hbs!core/widgets/header/toolsMenu",
  "core/utils/serviceRegistry",
  "i18n!./nls/i18_settingsMenu",
  "core/utils/i18n",
  "core/widgets/header/NavMenuItem"
], function(
  $,
  auth,
  pluginNav,
  userTemplate,
  toolsTemplate,
  sr,
  nls,
  i18n,
  NavMenuItem
) {
  const menuItems = [];

  const settingsMenu = {
    init: async function() {
      this.initToolsMenu();
      this.initUserMenu();

      document.body.addEventListener("click", this.closeAll);
    },

    /**
     * Tools Menu
     * Pulls the name of all apps that contribute and lists them in a dropdown
     */
    initToolsMenu: async function() {
      const template = toolsTemplate(nls);
      const toolsMenu = this.createMenu(".header-buttons #app-launcher", template);

      const applist = await sr.getService("cisco.dna.core.home", "applist");

      // Hide menu button if no apps
      if (!applist) {
        return;
      }
      if (!applist.length) {
        toolsMenu.button.css("display", "none");
        return;
      }

      for (let i = 0; i < applist.length; i++) {
        const app_href = applist[i].defaultRoute || applist[i].href;
        toolsMenu.append({
          href: app_href,
          title: applist[i].title,
          parent: "._slot.tools",
          className: ""
        });
      }
    },

    /**
     * User Menu
     * Show's the user's username, has a logout button
     */
    initUserMenu: async function() {
      this.rbac = await sr.getService("cisco.dna.core.rbac", "rbac");
      if (!this.rbac) {
        return;
      }
      const users = await this.rbac.getUserData();
      const { firstName, username } = users.users.find(
        user => user.username === window.USERNAME
      );

      // Create Menu
      const template = userTemplate(Object.assign({}, nls));
      const userMenu = this.createMenu(".apic-header-cog-settings", template);
      userMenu.template.find(".username").text(firstName ? firstName : username);

      // Add contributions
      const extendedNav = pluginNav.get("user-nav", "platform");
      // For backwards compatibility
      const systemAggregatedNav = extendedNav.concat(pluginNav.get("system", "platform"));

      userMenu.appendMultiple(
        systemAggregatedNav.map(item => ({
          href: item.href,
          title: item.title,
          className: `_${item.title.toLowerCase()}`,
          parent: "._slot.system"
        }))
      );

      userMenu.template.find("._signoutHolder").click(auth.signout);

      if (this.rbac.checkPermission("maglev-system.identitymgmt.default.resource", "gRead")) {
        const userNameHolder = userMenu.template.find("._usernameHolder");
        userNameHolder.addClass("enabled").removeClass("disabled");
        userNameHolder.click(() => {
          window.location
            = "/dna/systemSettings/userManagement?st-user-management=users";
        });
      }
    },

    // Create the NavMenuItem, and auto add event listeners
    createMenu(selector, template) {
      const menu = new NavMenuItem(selector, template);
      menuItems.push(menu);
      menu.on("open", this.closeAll);
      return menu;
    },

    // Close all open menus
    closeAll: () => {
      menuItems.forEach(n => n.close());
    }
  };

  return {
    init() {
      settingsMenu.init();
    }
  };
});
