import featureFlags from "../plugin/featureFlags";
const magneticId = "cisco.dna.core.theme/magnetic";

// this is for login page ONLY
// set login page theme when it is not authenticated/logged in
export const setTheme = () => {
  const themes = [
    {
      title: "dnx-theme-magnetic",
      href: "/dist/dnx/styles/magneticstyles.less.css"
    },
    {
      title: "hbr-theme-magnetic",
      href: "/dist/harbor-elements/styles/themes/magnetic.css"
    }
  ];
  const links = themes.map((t) => {
    const link = document.createElement("link");
    link.setAttribute("rel", "stylesheet");
    link.setAttribute("type", "text/css");
    link.setAttribute("href", t.href);
    return link;
  });

  function isSystemPreferredDarkMode(mode = "system") {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    try {
      if (mode === "dark") {
        return true;
      } else if (mode === "light") {
        return false;
      }
      return mediaQuery.matches;
    } catch {
      return false;
    }
  }

  const setTheme = (themeName) => {
    if (themeName === "hbr-theme-magnetic") {
      links.forEach((link) => {
        !document.body.contains(link) && document.body.appendChild(link);
      });
    } else {
      links.forEach((link) => {
        link.remove();
      });
    }
    document.body.classList.add(themeName);
  };

  if (featureFlags.isEnabled(magneticId)) {
    setTheme("hbr-theme-magnetic");
  } else if (!featureFlags.isEnabled(magneticId)) {
    setTheme("hbr-theme-cohesion-v1");
  }

  const isSystemInDarkMode = isSystemPreferredDarkMode();
  if (isSystemInDarkMode === "dark") {
    document.body.classList.add("dnac-theme__darkMode");
    document.body.classList.add("hbr-mode-dark");
  } else {
    document.body.classList.remove("dnac-theme__darkMode");
    document.body.classList.remove("hbr-mode-dark");
  }
};
