export default {
  "maintenance_in_progress": "正在维护",
  "restoring_backup": "正在恢复备份",
  "system_scale_in_progress": "正在扩展系统",
  "system_update_in_progress": "正在更新系统",
  "checking_system_update": "正在检查系统更新状态...",
  "checking_maintenance_mode": "正在检查维护模式状态...",
  "seconds": "秒",
  "minutes": "分钟",
  "took": "已花费",
  "complete": "完成",
  "of": "/",
  "completed": "任务已完成",
  "hide": "隐藏详细信息",
  "show": "显示详细信息",
  "phase": "阶段",
  "please": "升级完成后，您将自动跳转至 Cisco Catalyst Center。"

};
