const $ = require("jquery");
const axios = require("axios");


let cacheServerTime = null; // Holds the last dached serverTime response
let cacheServerTimeInProgress = false; // Whether or not there is a request in progress

const cacheServerTimeQueue = []; // Queues all deferreds while a serverTime call is in progress

module.exports = {
  getServerTime: function() {
    const dfd = new $.Deferred();
    if (!cacheServerTime) {
      if (!cacheServerTimeInProgress) {
        cacheServerTimeInProgress = true;
        axios.get("/serverTime").then(response => {
          cacheServerTime = response.data;
          cacheServerTimeInProgress = false;
          setTimeout(function() {
            cacheServerTime = null;
          }, 30000);
          for (let i = 0; i < cacheServerTimeQueue.length; i++) {
            cacheServerTimeQueue[i].resolve(response.data);
          }
          dfd.resolve(response.data);
        })
          .catch(error => {
            dfd.reject(error);
          });
      } else {
        cacheServerTimeQueue.push(dfd);
      }
    } else {
      dfd.resolve(cacheServerTime);
    }
    return dfd.promise();
  }
};
