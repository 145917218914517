//
// UI that provides the following developer-centric features:
//
// (a) Inspection of plugins installed on the platform, including their extension points and contributions.
// (b) Adding plugins by specifying urls to their ui-config.json.  Plugins added in this way are only visible to the client; other users using the same Platform UI instance don't see them.
//

import { platform } from "../harbor-platform";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import _ from "underscore";
import { DnxModal, DnxButton } from "../utils/dnxCustomElementsReactLoader";

import localStorage from "core/apicem/utils/localStorage";
import featureFlags from "core/plugin/featureFlags";

// returns an event handler that adds plugin url
// return by invoking valueFn
const addPluginsHandler = input => e => {
  e.preventDefault();
  const url = input.value;
  if (url) {
    platform.pluginRegistry.addDevPlugin(url).then(renderUI);
  }
};

// handler that clears all dev plugins
const removeAllDevPluginsHandler = e => {
  e.preventDefault();
  if (window.confirm("Are you sure?")) {
    platform.pluginRegistry.clearDevPlugins().then(renderUI);
  }
};

const IconButton = ({ onClick, type }) => (
  <button
    className="btn btn-primary"
    style={{ padding: 8, minWidth: 0, minHeight: 0, lineHeight: 1 }}
    onClick={onClick}
  >
    <span className={`fa fa-lg fa-${type}`} style={{}}/>
  </button>
);

class AddDevPluginsForm extends Component {
  constructor() {
    super();
    this.state = {
      removeAllDevPluginsOpen: false
    };
    this.pluginUrlRef = null;
  }

  closeRemoveAllDevPluginsModal = () => {
    this.setState({ removeAllDevPluginsOpen: false });
  };

  render() {
    const defaultLocalHostPluginUrl = `${
      window.location.protocol
    }//localhost:3002/ui-config.json`;
    const expandFlag = location.hash.includes("expand") ? true : false;
    return (
      <div style={{ marginTop: 20, marginBottom: 40 }}>
        {this.state.removeAllDevPluginsOpen && (
          <DnxModal
            noheader={true}
            width="360px"
            close={this.closeRemoveAllDevPluginsModal}
            dismissible={true}
          >
            <div style={{ padding: 16, textAlign: "center" }}>
              <div>Are you sure you want to delete all dev plugins?</div>
              <div style={{ marginTop: 20, display: "flex", justifyContent: "space-between" }}>
                <DnxButton
                  data-testid="myaccount__password-form-cancel-button"
                  label="Cancel"
                  flavor="link"
                  type="button"
                  click={this.closeRemoveAllDevPluginsModal}
                />
                <DnxButton
                  data-testid="myaccount__password-form-update-button"
                  label="Remove all"
                  flavor="primary"
                  type="submit"
                  click={(e) => {
                    this.closeRemoveAllDevPluginsModal();
                    removeAllDevPluginsHandler(e);
                  }}
                />
              </div>
            </div>
          </DnxModal>
        )}
        <div style={{ marginBottom: 10 }}>
          <form className="form-inline">
            <div
              className="form-group"
              style={{ display: "flex", alignItems: "baseline" }}
            >
              <span style={{ paddingRight: 10 }}>Dev plugin config url:</span>
              <input
                ref={el => this.pluginUrlRef = el}
                style={{ flexGrow: 1, maxWidth: 400, marginRight: 10 }}
                className="form-control"
                defaultValue={defaultLocalHostPluginUrl}
                placeholder="http://domain/path/to/ui-config.json"
              />
              <IconButton
                onClick={e => addPluginsHandler(this.pluginUrlRef)(e)}
                type="plus"
              />
            </div>
          </form>
        </div>
        <button
          style={{
            background: "transparent",
            border: "none",
            color: "var(--link-normal,#1170cf)",
            fontWeight: 400
          }}
          onClick={() => this.setState({ removeAllDevPluginsOpen: true })}
        >
          Remove all dev plugins
        </button>
        <button
          style={{
            marginLeft: "40px",
            background: "transparent",
            border: "none",
            color: "var(--link-normal,#1170cf)",
            fontWeight: 400
          }}
          onClick={() => {
            if (expandFlag) {
              location.hash = "";
            } else {
              location.href = `${location.origin}/developer#expand`;
            }
            location.reload();
          }}
        >
          {expandFlag ? "Collapse all config" : "Expand all config"}
        </button>
      </div>
    );
  }
}

const removePluginHandler = baseUrl => () => platform.pluginRegistry.removeDevPlugin(baseUrl).then(renderUI);

const isTree = node => _.isObject(node) && !_.isString(node) && !_.isNumber(node);

const TreeLineItem = ({ expandable, expanded, label, onExpand }) => (
  <button
    onClick={onExpand}
    style={
      expandable ? {
        cursor: "pointer",
        background: "transparent",
        border: "none"
      } : {
        background: "transparent",
        border: "none"
      }}>
    {<span>{expandable ? expanded ? "-" : "+" : <span>&nbsp;</span>} </span>}
    <span>{label}</span>
  </button>
);

const SubTree = ({ childKeys, node }) => (
  <div style={{ paddingLeft: 30 }}>
    {childKeys.sort().map(key => {
      const childNode = node[key];
      const childLabel = isTree(childNode) ? (
        key
      ) : (
        <span>
          <span>{key}: </span>
          <span style={{ fontWeight: "bold" }}>{childNode.toString()}</span>
        </span>
      );
      return <Tree key={key} node={childNode} label={childLabel}/>;
    })}
  </div>
);

//
// Renders the object indicated by the node prop as an
// expandable tree structure.  The prop onlyKeys can be
// provided restrict the set of rendered key/value pairs.
//
class Tree extends Component {
  constructor() {
    super();
    this.state = {
      expanded: false
    };
  }
  handleExpand() {
    if (isTree(this.props.node)) {
      this.setState({ expanded: !this.state.expanded });
    }
  }
  render() {
    const { node, label, onlyKeys } = this.props;
    const expandFlag = location.hash.includes("expand") ? true : false;
    const expanded = this.state.expanded || expandFlag;
    const childKeys = isTree(node)
      ? Object.keys(node).filter(key => !onlyKeys || onlyKeys.indexOf(key) !== -1)
      : [];
    const expandable = childKeys.length;
    return (
      <div style={{ fontFamily: "monospace" }}>
        <TreeLineItem
          {...{ expandable, expanded, label }}
          onExpand={this.handleExpand.bind(this)}
        />
        {expanded ? <SubTree {...{ node, childKeys }}/> : null}
      </div>
    );
  }
}

//
// Renders a header for a group of plugins; the header
// indicates the config url the plugins come from,
// or (bundled plugins) if no configUrl, which means
// the configs are served from the backend.
//
const PluginListSectionHeader = ({ configUrl, isDev }) =>
  configUrl ? (
    <div>
      <h3 style={{ display: "inline-block" }}>
        <a key={configUrl} href={configUrl} target="_blank" rel="noreferrer">
          {configUrl}
        </a>
      </h3>
      {isDev ? (
        <button
          style={{
            marginLeft: "40px",
            background: "transparent",
            border: "none",
            color: "var(--link-normal,#1170cf)",
            fontWeight: 400,
            paddingLeft: 10
          }}
          onClick={removePluginHandler(configUrl)}
        >
          (remove)
        </button>
      ) : null}
    </div>
  ) : (
    <h3>(bundled plugins)</h3>
  );

//
// Renders a list of plugins.  Each is rendered as
// a tree that can be expanded to see contributions
// and extension points within.
//
const PluginListItems = ({ configs }) => (
  <div>
    {_.sortBy(configs, "pluginId").map(config => {
      return (
        <div key={config.pluginId}>
          <Tree
            node={config}
            onlyKeys={["__configUrl", "baseUrl", "extensionPoints", "contributions"]}
            label={
              <span>
                {config.pluginId} {config.__isDevPlugin ? <span>(dev)</span> : null}
              </span>
            }
          />
        </div>
      );
    })}
  </div>
);

//
// Render a list of plugins, grouped by the config
// url that they come from.
//
const PluginList = ({ pluginConfigs }) => {
  const pluginConfigsByBaseUrl = _.groupBy(
    _.sortBy(pluginConfigs, c => (c.__configUrl ? 0 : 1)),
    c => c.__configUrl || ""
  );
  return (
    <div>
      {_.map(pluginConfigsByBaseUrl, (configs, configUrl) => (
        <div key={configUrl}>
          <PluginListSectionHeader
            configUrl={configUrl}
            isDev={configs[0].__isDevPlugin}
          />
          <PluginListItems configs={configs}/>
        </div>
      ))}
    </div>
  );
};

const PluginsPage = ({ pluginConfigs }) => {
  return (
    <div>
      <AddDevPluginsForm/>
      <PluginList pluginConfigs={pluginConfigs}/>
    </div>
  );
};

const magneticToggle = self => {
  if (self.props.storageKey === "cisco.dna.core.featureFlag.cisco.dna.core.theme/magnetic") {
    if (self.state.value === "true") {
      window.themeService.setTheme("hbr-theme-cohesion-v1");
    } else {
      window.themeService.setTheme("hbr-theme-magnetic");
    }
  }
};

//
// Checkbox that toggles a local storage value
// between the values "true" and "false"
//
class StorageToggle extends Component {
  constructor() {
    super();
    this.state = { value: null };
    this.toggle = () => {
      localStorage.setItem(
        this.props.storageKey,
        this.state.value === "true" ? "false" : "true"
      );
      magneticToggle(this);
      this.updateState();
    };
  }
  componentWillMount() {
    this.updateState();
  }
  updateState() {
    this.setState({ value: localStorage.getItem(this.props.storageKey) });
  }
  render() {
    const { value } = this.state;
    const { label } = this.props;
    return (
      <div className="checkbox">
        <label>
          <input type="checkbox" checked={value === "true"} onChange={this.toggle}/>
          <span className="checkbox-txt"/>
          {label}
        </label>
      </div>
    );
  }
}

const SettingsPage = () => (
  <div>
    <h3>Feature Flags</h3>
    <div>
      {_.map(_.groupBy(featureFlags.flags, "category"), (flags, categoryName) => {
        return (
          <div key={categoryName}>
            <h4>{categoryName}</h4>
            {flags.map(flag => (
              <StorageToggle
                key={flag.id}
                storageKey={featureFlags.storageKey(flag.id)}
                label={flag.label}
              />
            ))}
          </div>
        );
      })}
    </div>
  </div>
);

const Tab = ({ active, onClick, label }) => {
  return (
    <div
      style={{
        paddingBottom: "6px",
        marginRight: "20px",
        borderBottom: active ? "4px solid #049fd9" : "none"
      }}
    >
      <button
        style={{
          background: "transparent",
          border: "none",
          textDecoration: "none",
          color: "inherit",
          fontWeight: 400
        }}
        onClick={onClick}
      >
        <div className="">{label}</div>
      </button>
    </div>
  );
};

const Tabs = ({ tabs, activeTab, onChange }) => (
  <div
    style={{
      display: "flex",
      padding: "20px 20px 0 20px",
      borderBottom: "solid 1px #f2f2f2"
    }}
  >
    {tabs.map((t, idx) => (
      <Tab
        key={idx}
        active={activeTab === t.id}
        label={t.label}
        onClick={onChange.bind(null, t.id)}
      />
    ))}
  </div>
);

class Root extends Component {
  state = {
    activeTab: "plugins"
  };

  tabs = [
    { id: "plugins", label: "Plugins" },
    { id: "plugins-search", label: "Nav ID Lookup" },
    { id: "plugin-score", label: "Plugin Insights" },
    { id: "settings", label: "Settings" }
  ];

  setActiveTab = activeTab => {
    if (activeTab === "plugin-score") {
      window.location = "/dev/insights";
    } else if (activeTab === "plugins-search") {
      window.location = "/dev/navidlookup";
    } else {
      this.setState({ activeTab });
    }
  };

  render() {
    const { pluginConfigs } = this.props;
    const { activeTab } = this.state;

    return (
      <div>
        <Tabs tabs={this.tabs} activeTab={activeTab} onChange={this.setActiveTab}/>
        <div style={{ padding: "4rem" }}>
          {(() => {
            switch (activeTab) {
            case "plugins":
              return <PluginsPage pluginConfigs={pluginConfigs}/>;
            case "settings":
              return <SettingsPage/>;
            }
          })()}
        </div>
      </div>
    );
  }
}

export const renderUI = () => {
  ReactDOM.render(
    <Root pluginConfigs={platform.pluginRegistry.getPluginConfigs()}/>,
    document.getElementsByClassName("developerBody")[0]
  );
};
