const uniqueName = `?ts=${new Date().getTime()}`;

const script = document.createElement("script");
script.type = "module";
script.src = `/dist/harbor-elements/harbor-elements.esm.js${uniqueName}`;
const s = document.getElementsByTagName("script")[0];
s && s.parentNode.insertBefore(script, s);

const link = document.createElement("link");
link.rel = "stylesheet";
link.href = `/dist/harbor-elements/harbor-elements.css${uniqueName}`;
document.head && document.head.appendChild(link);

const magneticTokens = document.createElement("link");
magneticTokens.rel = "stylesheet";
magneticTokens.href = `/dist/harbor-elements/styles/tokens/base-font-10/magnetic.css${uniqueName}`;
document.head && document.head.appendChild(magneticTokens);

const magneticTheme = document.createElement("link");
magneticTheme.rel = "stylesheet";
magneticTheme.href = `/dist/harbor-elements/styles/themes/magnetic.css${uniqueName}`;
document.head && document.head.appendChild(magneticTheme);

const magneticDarkTokens = document.createElement("link");
magneticDarkTokens.rel = "stylesheet";
magneticDarkTokens.href = `/dist/harbor-elements/styles/tokens/base-font-10/magnetic.dark.css${uniqueName}`;
document.head && document.head.appendChild(magneticDarkTokens);

const magneticDarkTheme = document.createElement("link");
magneticDarkTheme.rel = "stylesheet";
magneticDarkTheme.href = `/dist/harbor-elements/styles/themes/magnetic.dark.css${uniqueName}`;
document.head && document.head.appendChild(magneticDarkTheme);

const magneticThemeDeprecated = document.createElement("link");
magneticThemeDeprecated.rel = "stylesheet";
magneticThemeDeprecated.href = `/dist/harbor-elements/styles/themes/magnetic-deprecated.css${uniqueName}`;
document.head && document.head.appendChild(magneticThemeDeprecated);
