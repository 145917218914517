window.define("@harbor/elements/reactWrapper", () =>
  //Below when switching to @harbor/elements version 1
  // require("@harbor/elements/utils/react/wrapper.jsx")
  require("@harbor/elements/utils/react/wrapper")
);
window.define("@harbor/elements/theme", () =>
  require("@harbor/elements/utils/theme.js")
);
window.define("@harbor/elements/timeZones", () =>
  require("@harbor/elements/utils/timeZones.js")
);
