const axios = require("axios").default;

module.exports = {
  getCurrentRelease: () =>
    axios.get("/api/system/v1/maglev/packages").then(data => {
      let version = "";
      let i = data.data.response.length;
      while (i--) {
        if (data.data.response[i].name === "system") {
          version = data.data.response[i].version;
        }
      }
      return version;
    })
      .catch((data) => {
        throw data.request;
      })
};
